import * as Yup from "yup";

const subEquipmentSchema = Yup.object().nullable().shape({
  toggle: Yup.boolean(),
  threshold: Yup.number().typeError("Must be a number")
    .min(2, "Threshold must be greater than 1")
    .moreThan(Yup.ref('start'), "Threshold must be more than start")
    .when('toggle', {
      is: true,
      then: Yup.number().required("Must enter a Threshold"),
    }),
  start: Yup.number().typeError("Must be a number")
    .min(1, "Start must be greater than 0")
    .lessThan(Yup.ref('threshold'), "Start must be less than Threshold")
    .when('toggle', {
      is: true,
      then: Yup.number().required("Must enter a Start"),
    }),
  percent: Yup.number().typeError("Must be a number")
    .min(1, "Percent must be greater than 0")
    .max(100, "Percent must be 100 or less")
    .when('toggle', {
      is: true,
      then: Yup.number().required("Must enter a Percent"),
    }),
});

const subLaborMultiplierSchema = Yup.object().nullable().shape({
  toggle: Yup.boolean(),
  multiplier: Yup.number().typeError("Must be a number")
    .when('toggle', {
      is: true,
      then: Yup.number().required("Must enter a Multiplier"),
    }),
});

const subLaborSchema = Yup.object().nullable().shape({
  toggle: Yup.boolean(),
  value: Yup.number().typeError("Must be a number")
    .when('toggle', {
      is: true,
      then: Yup.number().required("Must enter a Value"),
    }),
});

const subMarkupSchema = Yup.object().nullable().shape({
  toggle: Yup.boolean(),
  type: Yup.string(),
  percent: Yup.number().typeError("Must be a number")
    .when('type', {
      is: "percent",
      then: Yup.number().required("Must enter a Percent"),
    }),
  value: Yup.number().typeError("Must be a number")
    .when('type', {
      is: "flat",
      then: Yup.number().required("Must enter an Amount"),
    }),
});

export const tmTermsSchema = Yup.object().shape({
  field_tm_terms: Yup.object().nullable().shape({
    equipment: Yup.object().nullable().shape({
      weekly: subEquipmentSchema,
      monthly: subEquipmentSchema,
      markup: subMarkupSchema,
    }),
    labor: Yup.object().nullable().shape({
      after_hours: subLaborMultiplierSchema,
      overtime: subLaborMultiplierSchema,
      per_diem: subLaborSchema,
      ppe: subLaborSchema,
      small_tools_charge: subLaborSchema,
      lodging: subLaborSchema,
      markup: subMarkupSchema,
    }),
  }),
});
