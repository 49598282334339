import React from "react";
import { Grid, Typography, /*Collapse,*/ InputAdornment } from "@mui/material";
import { TextFormField, RadioFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";

import {
} from "components/TMTerms/TMTermsSlice";
import {
  getExcludedLaborTypes,
} from "components/TMTerms/utils";

const TMTermsMarkupComponent = ({
  smallDialog,
  tmType,
  styleProps,
  checked,
  selectedLaborTypeExcludes,
  laborTypes,
  setChecked,
  setInputChange,
  defaultTerms,
  values,
  setTMTermValues,
  isSubmitting,
  ...props
}) => {
  const checkIndex = `${tmType}_markup`;
  const typeValue = values?.[tmType]?.markup?.type
                      ? values?.[tmType]?.markup?.type
                      : values?.[tmType]?.markup?.value ? 'flat' : 'percent';
  const valueIndex = typeValue !== "flat" ? 'percent' : 'value';

  return (
    <Grid item xxs={12} style={{...styleProps.laborTermItem}}>
      <Grid container style={{...styleProps.subBody, paddingTop: 0, paddingBottom: 0}}>
        <Grid item xxs={12} style={styleProps.subHeading}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{margin: "0.5em 0 0.5em"}}
          >
            Markup
            {(tmType === 'labor' && checked?.[checkIndex]) && (
              <Grid item xxs={12}>{getExcludedLaborTypes(selectedLaborTypeExcludes, laborTypes, "markup", 'excludes')}</Grid>
            )}
          </Typography>
        </Grid>
        <Grid item xxs={(!smallDialog ? 1 : 2)} style={styleProps.labor.input}>
          <CheckboxFormField
            name={`field_tm_terms.${tmType}.markup.toggle`}
            checked={(checked?.[checkIndex] ? true : false)}
            value={(checked?.[checkIndex] ? true : false)}
            onChange={() => {
              const newChecked = !checked?.[checkIndex];
              if(newChecked /*&& defaultTerms?.[tmType]?.[checkIndex]?.[valueIndex]*/){
              //   setInputChange(defaultTerms[tmType][checkIndex][valueIndex], tmType, checkIndex, valueIndex);
                setInputChange("percent", tmType, "markup", 'type')
              }
              setChecked({...checked, [checkIndex]: newChecked});
            }}
            emptyLabel={true}
          />
        </Grid>
        <Grid item xxs={9} style={styleProps[tmType].input}>
          {/* {checked?.[checkIndex] && */}
            <Grid container style={{paddingTop: 0, paddingBottom: 0}}>
              {tmType !== 'equipment' &&
                <Grid item xxs={12} md={6} style={styleProps.subHeading}>
                  <RadioFormField
                    name={`field_tm_terms.${tmType}.markup.type`}
                    // aria="Who is receiving this notification?"
                    // label="Who is receiving this notification?"
                    margin="normal"
                    required={true}
                    disabled={isSubmitting || !checked?.[checkIndex]}
                    wrapperStyle={{ display: "flex" }}
                    defaultValue="percent"
                    options={[
                      {value: 'percent', label: "Percent"},
                      {value: 'flat', label: "Flat Amount"}
                    ]}
                    value={typeValue}
                    onChange={(e) =>{
                      setInputChange("", tmType, "markup", e.target.value === 'flat' ? 'value' : 'percent');
                      setInputChange(e.target.value, tmType, "markup", 'type');
                    }}
                    // orientation={f.orientation}
                  />
                </Grid>
              }
              <Grid item xxs={12} md={6} style={styleProps.subHeading}>
                <TextFormField
                  fullWidth
                  name={`field_tm_terms.${tmType}.markup.${valueIndex}`}
                  label={valueIndex[0].toLocaleUpperCase() + valueIndex.slice(1)}
                  required={(checked?.[checkIndex] ? true : false)}
                  variant="outlined"
                  size="small"
                  value={(checked?.[checkIndex] &&
                    values?.[tmType]?.markup?.[valueIndex]
                      ? (valueIndex === 'percent' ? Number(values?.[tmType].markup[valueIndex]) * 100 : values?.[tmType].markup[valueIndex])
                      : ''
                  )}
                  disabled={isSubmitting || !checked?.[checkIndex]}
                  onChange={(e) => {
                    let value = e.target.value;
                    if(valueIndex === 'percent'){
                      value = Number(value) / 100;
                    }
                    setInputChange(value, tmType, "markup", valueIndex)
                  }}
                  startAdornment={
                    (valueIndex === 'value'
                      ? <InputAdornment position="start">$</InputAdornment>
                      : (valueIndex === 'multiplier' ? <InputAdornment position="start">x</InputAdornment> : null)
                    )
                  }
                  endAdornment={
                    (valueIndex === 'percent' ? <InputAdornment position="end">%</InputAdornment> : null)
                  }
                />
                 {/* <CheckboxFormField
                  name={`field_tm_terms.${tmType}.markup.invoice_show`}
                  label="Show Markup on Invoice?"
                  checked={checked?.[checkIndex] && Boolean(values?.[tmType]?.markup?.invoice_show)}
                  value={checked?.[checkIndex] && Boolean(values?.[tmType]?.markup?.invoice_show)}
                  disabled={isSubmitting || !checked?.[checkIndex]}
                  onChange={(e) => {
                    setInputChange(String(e.target.value) === 'false' ? true : false, tmType, "markup", "invoice_show")
                  }}
                /> */}
              </Grid>
            </Grid>
          {/* } */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TMTermsMarkupComponent;
