import { formatField, splitEscapedCommaString } from "utility";

export const formatUserForListing = (d) => {
  return {
    uid: formatField(d, "uid"),
    name: formatField(d, "name"),
    mail: formatField(d, "mail"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    access: formatField(d, "access"),
    status: formatField(d, "status"),
    first_name: formatField(d, "first_name"),
    last_name: formatField(d, "last_name"),
    title_position: formatField(d, "title_position"),
    phone: formatField(d, "phone"),
    profile_pic_uri: formatField(d, "profile_pic_uri"),
    phx_client_nids: formatField(d, "phx_client_nids"),
    _files: { profile_pic: null },
  };
};

export const formatUserForManage = (d) => {
  return {
    ...d,
    role_names: splitEscapedCommaString(d?.role_names),
    member_names: splitEscapedCommaString(d?.member_names),
    customer_names: splitEscapedCommaString(d?.customer_names),
    profile_pic: d?._files?.profile_pic,
    userPath: d?._paths?.user,
  };
};

export const formatTempUserForListing = (d) => {
  let userSettings = formatField(d, "field_user_settings");
  if(!userSettings){
    userSettings = {}
  }
  if(userSettings.service_types){
    userSettings.service_types = JSON.parse(userSettings.service_types);
  }
  else{
    userSettings.service_types = {};
  }
  return {
    nid: formatField(d, "nid"),
    full_name: formatField(d, "title"),
    email: formatField(d, "field_email"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    status: formatField(d, "status"),
    field_user_settings: userSettings,
    // field_sms_settings: d.field_sms_settings,
  };
};
