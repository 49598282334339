import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import IconButtonDropdown from 'common/ButtonDropdown'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'


const ColorPicker = ({ saveLogic, defaultColor, fieldName }) => {
    const [color, setColor] = useState({ hex: defaultColor ? defaultColor : '#000000' })
    return (
        <div className='color-picker-wrapper flex'>
            <IconButtonDropdown
                variant="outlined"
                label={<>
                    <ArrowDropDown className='-ml-2' />
                    <div className='h-4 w-4' style={{ backgroundColor: color.hex }}></div>
                </>}
                tooltip="Change Color"
                size="small"
                className="p-1"
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                >
                <SketchPicker color={color} onChange={(e) => {
                    setColor(e)
                    saveLogic(fieldName, e.hex)
                } } />
            </IconButtonDropdown>
        </div>
    )
}

ColorPicker.propTypes = {}

ColorPicker.defaultProps = {}

export default ColorPicker
