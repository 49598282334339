
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "common/Avatar";
import {
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { getDivisionDataSelector, } from "features/Job/jobSlice";
import { TimeClockDialog } from "components/TimeClock";
import { BsCalendar4Event, BsArrowRight } from "react-icons/bs";
import { HoursInfo, TimeInfo } from "./TimeClockTimer";
import { ClockIcon } from "common/ClockIcon";
import { BsPerson, BsTools } from "react-icons/bs";
import moment from "moment-timezone";
import { size } from "lodash";
import Dialog from "common/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { TrashIcon } from "@heroicons/react/outline";
import { publishToggleTimeCard } from "../timeClockSlice";
import ConfirmationDialog from "components/ConfirmationDialog";


const TimeCardUser = ({
  user,
  onTimeCardClose,
  summary,
  onClose,
  isUserSummary,
  handleRefresh,
}) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteHash, setDeleteHash] = useState(null);
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);
  const timeClockActive = (size(division) ? division?._processed?.time_clock_active : false);
  const allowDelete = (timeClockActive && division?._processed?.job_access_type === 'admin');

  const handleDelete = async () => {
    setDeleteSubmitting(true);
    dispatch(publishToggleTimeCard({
      hash: division.field_public_hash,
      service_hash: deleteHash,
      published: 0
    })).then((resp) => {
      setOpenDelete(false);
      setDeleteSubmitting(false);
      handleRefresh();
    });
  };

  return (
    <>
      <div className={`time-card-user ${!summary ? 'detail' : ''}`}>
        {!isUserSummary &&
        <div
          className={`user-heading ${summary ? 'summary' : ''}`}
          onClick={() => {
            if(summary){
              setOpen(true);
            }
          }}
        >
          <div className="avatar">
            <Avatar
              title={`${user?.ds_user_full_name}`}
              link={user._files?.user_profile_pic_medium ? user._files?.user_profile_pic_medium : null}
              size="large"
            />
          </div>
          <div className="info">
            <Typography variant="h5">{user.ds_user_full_name}</Typography>
            <Typography variant="subtitle2">{user.ds_user_uid ? `Account User` : `Offline User`}</Typography>
          </div>
          {!summary &&
            <div className="detail-close">
              <IconButton
                onClick={onClose}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </div>
          }
        </div>
        }

        <div className="cards">
          {user.time_cards
            .filter((card, j) => !summary || j < 4)
            .map((card, j) =>
              <TimeClockDialog
                divisionHash={division.field_public_hash}
                serviceHash={card.hash}
                onClose={() => onTimeCardClose(user.user_id)}
                key={j}
              >
                <div className="wrapper" >
                  <div className="card">

                    <div className="heading">
                      <Typography  variant="subtitle1">
                        <BsCalendar4Event className="icon" />
                        {moment(card.date).format('MM/DD/YY')}
                        {(card.service_end && moment(card.service_end).format('MM/DD/YY') !== moment(card.service_start).format('MM/DD/YY')) &&
                          ` - ${moment(card.service_end).format('MM/DD/YY')}`
                        }
                      </Typography>
                      <div className="status">
                        <ClockIcon
                          className="icon"
                          run={card.clock_status === 'clocked_in'}
                        />
                        {card.clock_status === 'on_break' ? (
                          <span className="text-yellow">On Break</span>
                        ) : (
                          <>
                          {card.status === 'in_progress' &&
                            <span className="text-orange">In Progress</span>
                          }
                          {card.status === 'awaiting_signature' &&
                            <span className="text-red">Awaiting Signature</span>
                          }
                          {card.status === 'complete' &&
                            <span className="text-green-alt">Completed</span>
                          }
                          </>
                        )}
                      </div>
                      {allowDelete &&
                        <>
                        <Tooltip title="Delete Time Card">
                          <div
                            className="icon delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteHash(card.hash);
                              setOpenDelete(true);
                            }}
                          >
                            <TrashIcon />
                          </div>
                        </Tooltip>
                        </>
                      }
                    </div>

                    {!summary &&
                      <div className="labor-info">
                        <span>
                          <BsPerson className="icon small" /> {card.service_type}
                        </span>
                        {card.service_activity &&
                          <span>
                            <BsTools className="icon small" /> {card.service_activity}
                          </span>
                        }
                      </div>
                    }
                    {(!summary || card.status !== 'complete') &&
                      <TimeInfo timeClock={card} simple />
                    }
                    <HoursInfo timeClock={card} hideEmpty />

                  </div>
                </div>
              </TimeClockDialog>
          )}
        </div>
        {(summary && size(user.time_cards) > 4) &&
          <div
            className="more-cards"
            onClick={() => setOpen(true)}
          >
            <span className="text">
              More Time Cards <BsArrowRight className="icon" />
            </span>
          </div>
        }
      </div>
      {summary &&
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
        >
          <TimeCardUser
            user={user}
            onTimeCardClose={onTimeCardClose}
            onClose={() => setOpen(false)}
            allowDelete={allowDelete}
            // isUserSummary
          />
        </Dialog>
      }
      <ConfirmationDialog
        open={openDelete}
        onClose={() => {
          setDeleteHash(null);
          setOpenDelete(false);
        }}
        onSubmit={handleDelete}
        title="Are you sure?"
        body="Are you sure you want to delete this time card?"
        isSubmitting={deleteSubmitting}
        submitButton="Delete"
      />
    </>
  );
};

TimeCardUser.propTypes = {};

export default TimeCardUser;
