import moment from "moment";
import {
  formatField,
  getTaxonomyFields,
  getTempUserFields,
  getUserFields,
} from "../../utility";

export const fields = [
  { field: "Unprocessable Entity", label: "Error Saving" },
  { field: "field_ds_user", label: "User" },
];

export const parseFormServiceTimes = function (values) {
  const date = (values.field_ds_date ? values.field_ds_date.format("YYYY-MM-DD") : moment(values.date).format("YYYY-MM-DD"));
  const start = (
                      values.field_ds_start_time ? values.field_ds_start_time :
                      (values.start ? moment(values.start).format("HH:mm:00") : null)
                    );
  const end = (
                      values.field_ds_end_time ? values.field_ds_end_time :
                      (values.end ? moment(values.end).format("HH:mm:00") : null)
                    );
  const breakStart = (
                      values.field_ds_breaks_start_time ? values.field_ds_breaks_start_time :
                      (values.breakstart ? moment(values.breakstart).format("HH:mm:00") : null)
                    );
  const breakEnd = (
                      values.field_ds_breaks_end_time ? values.field_ds_breaks_end_time :
                      (values.breakend ? moment(values.breakend).format("HH:mm:00") : null)
                    );
  let times = {
    start: moment(
      `${date} ${start}`,
      ["YYYY-MM-DD h:mm A"]
    ),
    end: moment(
      `${date} ${end}`,
      ["YYYY-MM-DD h:mm A"]
    ),
    breakStart: (breakStart
      ? moment(
        `${date} ${breakStart}`,
        ["YYYY-MM-DD h:mm A"]
      )
      : null
    ),
    breakEnd: (breakEnd
      ? moment(
        `${date} ${breakEnd}`,
        ["YYYY-MM-DD h:mm A"]
      )
      : null
    ),
  };

//  Make the end time the next morning if end is lower that start
  if(moment(times.end) < moment(times.start)){
    times.end = times.end.add(1, 'days');
  }
  if(times.breakStart && moment(times.breakStart) < moment(times.start)){
    times.breakStart = times.breakStart.add(1, 'days');
  }
  if(times.breakEnd && (moment(times.breakEnd) < moment(times.start) || moment(times.breakEnd) < moment(times.breakStart))){
    times.breakEnd = times.breakEnd.add(1, 'days');
  }

  return times;
};

export const dsServiceOvertimeHrs = function (
  totals,
  userHours,
  date,
  start,
  divisionNid,
  nid
) {
  if (!date || !totals)
    return { serviceHours: 0, afterHours: 0, overtimeHours: 0 };
  const weekStart = moment(date).startOf("week").valueOf();
  const thisDate = moment(date).valueOf();
  const thisStart = start ? start.valueOf() : 0;
  //const weekEnd = moment(date).endOf("week").valueOf();
  let { serviceHours, afterhoursHours } = totals;
  let prevHours = 0;
  for (let i in userHours) {
    const hoursDate = moment(`${userHours[i].date}T00:00:00`).valueOf();
    const hoursStart = moment(userHours[i].start_time).valueOf();
    if (
      hoursDate >= weekStart &&
      // hoursDate <= moment(date).valueOf() &&
      (hoursDate < thisDate ||
        (hoursDate === thisDate && hoursStart < thisStart)) && // eslint-disable-line
      (!nid || userHours[i].ds_nid !== nid) &&
      Number(userHours[i].division_nid) === Number(divisionNid)
    ) {
      prevHours += Number(userHours[i].hours);
    }
  }

  let newHours = 0;
  newHours += serviceHours;
  newHours += afterhoursHours;

  let overtimeHours = 0;
  if (prevHours + newHours > 40) {
    if (prevHours >= 40) {
      overtimeHours = newHours;
      serviceHours = 0;
      afterhoursHours = 0;
    } else {
      overtimeHours = prevHours + newHours - 40;
      newHours -= overtimeHours;
      if (overtimeHours >= afterhoursHours) {
        afterhoursHours = 0;
        serviceHours = 40 - prevHours;
      } else {
        afterhoursHours -= overtimeHours;
      }
    }
  }

  return {
    serviceHours,
    overtimeHours,
    afterHours: afterhoursHours,
  };
};

export const calcTotal = (hours, rate) => {
  const totalHours = hours < 0 ? 0 : hours;
  return totalHours * rate;
};

export const calcTotalAmt = (rate, billable) => {
  const total = calcTotal(billable, rate);
  return Number(total).toFixed(2);
};

export const calcServiceHours = (start, end) => {
  const ms = moment(moment(end).format("YYYY-MM-DD HH:mm")).diff(
    moment(start).format("YYYY-MM-DD HH:mm")
  );
  const hours = ms / 3600000;

  return hours < 0 ? 0 : hours;
};

export const calcSmallTools = (values, total) => {
  if (values && values["has_stc"] && values["small_tools"]) {
    let _total = 0;
    if(typeof total === 'undefined'){
      const {service, after, overtime } = calcHoursTotals(values);
      _total = service + overtime + after;
    }
    else{
      _total = total;
    }
    return _total * (Number(values["small_tools"]) / 100);
  }
  return 0;
};

export const calcMarkup = (values, total) => {
  let markup = 0;
  if(values.markup_type === 'flat' && Number(values.field_ds_markup) > 0){
    markup = Number(values.field_ds_markup);
  }

  if(values.markup_type === 'percent' && Number(values.field_ds_percent_markup) > 0){
    let _total = 0;
    if(typeof total === 'undefined'){
      const {service, after, overtime } = calcHoursTotals(values);
      _total = service + overtime + after;
    }
    else{
      _total = total;
    }
    markup = _total * (Number(values.field_ds_percent_markup) / 100)
  }
  return markup;
};

export const calcHoursTotals = (values) => {
  const service = calcTotal(values["service-billable"], values["service-rate"]);
  const travel = calcTotal(values["travel-billable"], values["travel-rate"]);
  const overtime = calcTotal(
    values["overtime-billable"],
    values["overtime-rate"]
  );
  const after = calcTotal(values["after-billable"], values["after-rate"]);
  return {
    service: service,
    travel: travel,
    after: after,
    overtime: overtime,
  };
};

export const calcOverallTotal = (values) => {
  const {service, travel, after, overtime } = calcHoursTotals(values);
  // const service = calcTotal(values["service-billable"], values["service-rate"]);
  // const travel = calcTotal(values["travel-billable"], values["travel-rate"]);
  // const overtime = calcTotal(
  //   values["overtime-billable"],
  //   values["overtime-rate"]
  // );
  // const after = calcTotal(values["after-billable"], values["after-rate"]);
  const smallTools = calcSmallTools(values);
  const markup = calcMarkup(values);

  return (
    Number(service) +
    Number(travel) +
    Number(overtime) +
    Number(after) +
    Number(values.perdiem) +
    Number(values.ppe) +
    Number(values.lodging) +
    smallTools +
    markup
  );
};

export const calcOverallHours = (values) => {
  const service = values["service-billable"];
  const travel = values["travel-billable"];
  const overtime = values["overtime-billable"];
  const after = values["after-billable"];

  return (
    Math.round(
      (Number(service) + Number(travel) + Number(overtime) + Number(after)) *
        100
    ) / 100
  );
};

export const calcFlatTotal = (values) => {
  const service = values["service-total"];
  const travel = values["travel-total"];
  const overtime = values["overtime-total"];
  const after = values["after-total"];
  const smallTools = calcSmallTools(
    values/*["small_tools"],
    Number(service),
    Number(overtime),
    Number(after)*/
  );
  const markup = calcMarkup(values);

  return (
    Number(service) +
    Number(travel) +
    Number(overtime) +
    Number(after) +
    Number(values.perdiem) +
    Number(values.ppe) +
    Number(values.lodging) +
    smallTools +
    markup
  );
};

export const calcFlatHours = (values) => {
  const service = values["service-hours"];
  const travel = values["travel-hours"];
  const overtime = values["overtime-hours"];
  const after = values["after-hours"];

  return (
    Math.round(
      (Number(service) + Number(travel) + Number(overtime) + Number(after)) *
        100
    ) / 100
  );
};

export const calcBreakHours = (
  date,
  start,
  end,
  breakstart,
  breakend,
  totals,
  memberHours,
  setFieldValue
) => {
  if (!totals || !date)
    return {
      serviceHours: 0,
      overtimeHours: 0,
      afterhoursHours: 0,
    };
  let { serviceHours, afterhoursHours } = totals;
  const hours = getBusinessHrs(date, memberHours);
  let breakHoursTotal = 0;

  if (!breakstart || !breakend)
    return {
      serviceHours,
      overtimeHours: 0,
      afterhoursHours,
    };

  const times = parseFormServiceTimes({
    date: date,
    start: start,
    end: end,
    breakstart: breakstart,
    breakend: breakend,
  });

  let breakStartTime = moment(times.breakStart);

  let breakEndTime = moment(times.breakEnd);

  const startTime = moment(times.start);

  const endTime = moment(times.end);

  if (breakEndTime <= startTime || endTime <= breakStartTime)
    return {
      serviceHours,
      overtimeHours: 0,
      afterhoursHours,
    };
  if (breakStartTime < startTime) {
    breakStartTime = startTime;
  }
  if (breakEndTime > endTime) {
    breakEndTime = endTime;
  }

  const breakHours = (breakEndTime - breakStartTime) / 1000 / 60 / 60;

  if (breakHours <= 0)
    return {
      serviceHours,
      overtimeHours: 0,
      afterhoursHours,
    };

  breakHoursTotal += breakHours;

  if (hours["24hours"]) {
    serviceHours -= breakHours;
  } else {
    const open = moment(
      `${moment(date).format("YYYY-MM-DD")}T${hours["open"]}`
    );
    const close = moment(
      `${moment(date).format("YYYY-MM-DD")}T${hours["close"]}`
    );

    //  Break is entirely outside of business hours
    if (breakEndTime <= open || breakStartTime >= close || hours["closed"]) {
      afterhoursHours -= breakHours;
    }
    //  Break is entirely inside of business hours
    else if (breakEndTime >= open && breakEndTime <= close) {
      serviceHours -= breakHours;
    }
    //  Break is partially inside and outside of business hours
    //    before hours
    else if (breakStartTime < open) {
      afterhoursHours -= (open - breakStartTime) / 1000 / 60 / 60;
      serviceHours -= (breakEndTime - open) / 1000 / 60 / 60;
    }
    //    after hours
    else if (breakEndTime > close) {
      afterhoursHours -= (breakEndTime - close) / 1000 / 60 / 60;
      serviceHours -= (close - breakStartTime) / 1000 / 60 / 60;
    }
  }

  if (afterhoursHours < 0) {
    serviceHours -= 0 - afterhoursHours;
  }
  if (serviceHours < 0) {
    serviceHours = 0;
  }

  setFieldValue("breakHours", breakHoursTotal);
  return {
    serviceHours: serviceHours,
    overtimeHours: 0,
    afterhoursHours: afterhoursHours,
  };
};

export const calcAfterHoursHrs = (date, start, end, memberHours) => {
  if (!date || !start || !end)
    return {
      serviceHours: 0,
      overtimeHours: 0,
      afterhoursHours: 0,
    };
  const times = parseFormServiceTimes({
    date: date,
    start: start,
    end: end,
  });
  let serviceHours = calcServiceHours(times.start, times.end);
  let afterhoursHours = 0;
  const hours = getBusinessHrs(date, memberHours);
  if (!hours["24hours"]) {
    if (hours["closed"]) {
      afterhoursHours = serviceHours;
    } else {
      const open = moment(
        `${moment(date).format("YYYY-MM-DD")}T${hours["open"]}`
      );
      const close = moment(
        `${moment(date).format("YYYY-MM-DD")}T${hours["close"]}`
      );

      const startTime = moment(times.start);

      const endTime = moment(times.end);

      if (endTime < open || close < startTime) {
        afterhoursHours += (endTime - startTime) / 1000 / 60 / 60;
      } else {
        if (startTime < open) {
          afterhoursHours += (open - startTime) / 1000 / 60 / 60;
        }
        if (endTime > close) {
          afterhoursHours += (endTime - close) / 1000 / 60 / 60;
        }
      }
    }
  }
  if (afterhoursHours > 0) {
    if (serviceHours > afterhoursHours) {
      serviceHours -= afterhoursHours;
    } else {
      serviceHours = 0;
    }
  }

  return {
    serviceHours: serviceHours,
    overtimeHours: 0,
    afterhoursHours: afterhoursHours,
  };
};

export const getBusinessHrs = (date, memberHours) => {
  if (!memberHours)
    return {
      open: null,
      close: null,
      closed: null,
      "24hours": 1,
    };
  const day = moment(date).day();
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  let open = memberHours[`${days[day]}_open`];
  if(!open || open === ''){
    open = '09:00:00';
  }
  let close = memberHours[`${days[day]}_close`];
  if(!close || close === ''){
    close = '17:00:00';
  }
  return {
    open: open,
    close: close,
    closed: Number(memberHours[`${days[day]}_closed`]),
    "24hours": Number(memberHours[`${days[day]}_24hours`]),
  };
};

export const setTotalHours = (
  date,
  start,
  end,
  breakStart,
  breakEnd,
  memberHours,
  setFieldValue,
  values,
  userHours,
  divisionNid,
  nid
) => {
  const afterHoursHours = calcAfterHoursHrs(date, start, end, memberHours);
  const breakHoursHours = calcBreakHours(
    date,
    start,
    end,
    breakStart,
    breakEnd,
    afterHoursHours,
    memberHours,
    setFieldValue
  );

  const hours = dsServiceOvertimeHrs(
    breakHoursHours,
    userHours,
    date,
    start,
    divisionNid,
    nid
  );

  if (!values["billable-service-override"]) {
    setFieldValue(
      "service-billable",
      Math.round(hours.serviceHours * 100) / 100
    );
  }
  if (!values["billable-overtime-override"]) {
    setFieldValue(
      "overtime-billable",
      Math.round(hours.overtimeHours * 100) / 100
    );
  }
  if (!values["billable-after-override"]) {
    setFieldValue("after-billable", Math.round(hours.afterHours * 100) / 100);
  }
};

export const checkOvertimeError = (response, setWarningMessage) => {
  const overtimeError =
    response.data &&
    response.data._processed &&
    response.data._processed.ds_service_overtime_message
      ? response.data._processed.ds_service_overtime_message
      : null;
  if (overtimeError) {
    if (overtimeError === "overtime_wrong_day_but_has_overrides") {
      setWarningMessage({
        title: "This entry has been saved with a warning",
        body: "An Entry for this User during the work week has an override in place. As a result overtime could not be distributed properly.",
      });
    } else if (overtimeError === "overtime_wrong_day_validation_required") {
      setWarningMessage({
        title: "This entry has been saved with a warning",
        body: "The total hours for this user results in overtime and has been redistributed to the proper days of the work week, as a result one or more days for this week will need re-validation.",
      });
    }
  }
};

export const formatService = (ds) => {
  return {
    links: ds && ds._links ? ds._links : null,
    nid: formatField(ds, "nid"),
    type: formatField(ds, "type"),
    status: formatField(ds, "field_ds_service_status"),
    laborTypeTaxonomy: getTaxonomyFields(ds, "field_ds_labor_type"),
    date: formatField(ds, "field_ds_date"),
    start: formatField(ds, "field_ds_start_time"),
    end: formatField(ds, "field_ds_end_time"),
    user: getUserFields(ds, "field_ds_user"),
    tempUser: getTempUserFields(ds, "field_ds_user_temp"),
    activity: formatField(ds, "field_ds_service_activity_text"),
    activityTaxonomy: getTaxonomyFields(ds, "field_ds_service_activity"),
    labor: formatField(ds, "field_ds_labor_type_text"),
    coordinates: formatField(ds, "field_ds_coordinates"),
    billable: formatField(ds, "field_ds_billable"),
    excludeInvoice: formatField(ds, "field_ds_exclude_invoice"),
    onsite: formatField(ds, "field_ds_onsite"),
    afterHoursBillHrs: formatField(ds, "field_ds_after_hours_bill_hrs"),
    afterHoursBillOvrHrs: formatField(ds, "field_ds_after_hours_bll_ovr_hrs"),
    afterHoursHrs: formatField(ds, "field_ds_after_hours_hrs"),
    afterHoursOvrRate: formatField(ds, "field_ds_after_hours_ovr_rate"),
    afterHoursRate: formatField(ds, "field_ds_after_hours_rate"),
    afterHoursTotal: formatField(ds, "field_ds_after_hours_total"),
    billableHrs: formatField(ds, "field_ds_billable_hrs"),
    hasPerdiem: formatField(ds, "field_ds_has_perdiem"),
    perdiem: formatField(ds, "field_ds_perdiem_total"),
    ppe: formatField(ds, "field_ds_service_ppe_total"),
    smallToolsPercent: formatField(ds, "field_ds_service_stc_prct"),
    smallTools: formatField(ds, "field_ds_service_stc_total"),
    lodging: formatField(ds, "field_ds_service_lodging_total"),
    field_ds_taxable: formatField(ds, "field_ds_taxable"),
    //   workarea: formatWorkarea(
    //     ds.field_ds_work_area_allocation && ds.field_ds_work_area_allocation[0]
    //       ? ds.field_ds_work_area_allocation[0]
    //       : null
    //   ),
    field_ds_markup: formatField(ds, "field_ds_markup"),
    field_ds_percent_markup: formatField(ds, "field_ds_percent_markup"),
    breaks: {
      start:
        ds && ds.field_ds_breaks && ds.field_ds_breaks[0]
          ? formatField(ds.field_ds_breaks[0], "field_ds_breaks_start_time")
          : null,
      end:
        ds && ds.field_ds_breaks && ds.field_ds_breaks[0]
          ? formatField(ds.field_ds_breaks[0], "field_ds_breaks_end_time")
          : null,
    },
    breakParagraphId:
      ds && ds.field_ds_breaks && ds.field_ds_breaks[0]
        ? formatField(ds.field_ds_breaks[0], "id")
        : null,
    breakHrs: formatField(ds, "field_ds_break_hrs"),
    entryType: formatField(ds, "field_ds_entry_type"),
    overrideBillHrs: formatField(ds, "field_ds_override_billable_hrs"),
    overrideRate: formatField(ds, "field_ds_override_rate"),
    overtimeBillHrs: formatField(ds, "field_ds_overtime_billable_hrs"),
    overtimeBillOvrHrs: formatField(ds, "field_ds_overtime_bill_ovr_hrs"),
    overtimeHrs: formatField(ds, "field_ds_overtime_hrs"),
    overtimeOvrRate: formatField(ds, "field_ds_overtime_ovr_rate"),
    overtimeRate: formatField(ds, "field_ds_overtime_rate"),
    overtimeTotal: formatField(ds, "field_ds_overtime_total"),
    rate: formatField(ds, "field_ds_rate"),
    rateType: formatField(ds, "field_ds_rate_type"),
    serviceHrs: formatField(ds, "field_ds_service_hrs"),
    serviceTotal: formatField(ds, "field_ds_service_total"),
    total: formatField(ds, "field_ds_total"),
    totalHrs: formatField(ds, "field_ds_total_hours"),
    travelHrs: formatField(ds, "field_ds_travel_hrs"),
    travelBillHrs: formatField(ds, "field_ds_travel_billable_hrs"),
    travelBillOvrHrs: formatField(ds, "field_ds_travel_billable_ovr_hrs"),
    travelOvrRate: formatField(ds, "field_ds_travel_ovr_rate"),
    travelRate: formatField(ds, "field_ds_travel_rate"),
    travelTotal: formatField(ds, "field_ds_travel_total"),
    description: formatField(ds, "field_ds_private_description"),
    showDescription: formatField(ds, "field_ds_show_descript_invoice"),
    summary: ds?._processed?.ds_summary
  };
};
