import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";

import ButtonLoader from "common/ButtonLoader";
import Alert from "common/Alert";
import { TextFormField } from "common/Fields";
import { BsPencilSquare } from "react-icons/bs";
import { IoCarOutline } from "react-icons/io5";


const TimeClockTravel = ({
  // open,
  onClose,
  message,
  submitForm,
  isSubmitting,
  submitButton,
  timeClock,
  values,
  errors,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
    {timeClock?.service &&
      <div className="service-travel">
        <IoCarOutline className="edit-icon medium" />
        <span className="label">Travel Hours:</span> {Number(timeClock?.service_travel).toFixed(2)}
        {!timeClock.read_only && timeClock.service_status !== 'complete' &&
          <Tooltip title="Update Work Performing">
            <><BsPencilSquare className="edit-icon" onClick={() => setOpen(true)} /></>
          </Tooltip>
        }
      </div>
    }
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="time-clock-service-travel-dialog-title"
      aria-describedby="time-clock-service-travel-dialog-description"
      className="time-clock time-clock-dialog"
    >
      <DialogTitle id="time-clock-service-travel-dialog-title" className="dialog-title"><IoCarOutline className="edit-icon large" /> Travel Hours</DialogTitle>
      <DialogContent>
        {message && <Alert kind={message.id}>{message.msg}</Alert>}
        <div id="time-clock-service-travel-dialog-description">
          <TextFormField
            fullWidth
            htmlFor="service_travel"
            name="service_travel"
            label="Travel Hours"
            margin="none"
            disabled={isSubmitting}
            shrink
            size="small"
            labelwidth={55}
            type="number"
          />
        </div>
      </DialogContent>
      <DialogActions>
        {open &&
          <Button onClick={() => setOpen(false)} disabled={isSubmitting}>
            Cancel
          </Button>
        }
        <ButtonLoader
          variant="contained"
          color="primary"
          type="submit"
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
          disableElevation
          size="small"
          onClick={() => {
            submitForm('travel');
            setOpen(false);
          }}
        >
          Update
        </ButtonLoader>
      </DialogActions>
    </Dialog>
    </>
  );
};

TimeClockTravel.propTypes = {};

export default TimeClockTravel;
