import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  getUserFields,
} from "../../utility";

import { formatWorkAreaFields } from "../WorkArea/utils";

export const formatEquipment = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "ds_equipment",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_ds_date: formatField(d, "field_ds_date"),
    field_ds_user: getUserFields(d, "field_ds_user"),
    field_ds_total: formatField(d, "field_ds_total"),
    field_ds_taxable: formatField(d, "field_ds_taxable"),
    field_ds_work_area_allocation: formatWorkAreaFields(
      d?.field_ds_work_area_allocation?.[0]
    ),
    field_ds_markup: formatField(d, "field_ds_markup"),
    field_ds_percent_markup: formatField(d, "field_ds_percent_markup"),
    resources:
      d && d._processed && d._processed.resources
        ? d._processed.resources
        : null,
    summary: d?._processed?.ds_summary
  };
};

export const formatIncrements = (inc, singularize) => {
  let increment = inc;
  if(increments?.[inc]){
    increment = increments[inc];
  }
  if(singularize){
    increment = increment.replace(/\(s\)/gi, "").replace(/\(es\)/gi, "");
  }
  return increment;
}

export const increments = {
  h: "Hour(s)",
  d: "Day(s)",
  e: "Each",
  df: "Day(s) + Fuel",
  mi: "MI(s)",
  p: "Pair(s)",
  b: "Box(es)",
  c: "Can(s)",
  g: "Gallon(s)",
  r: "Roll(s)",
  cs: "CS",
  s: "Set(s)",
  pk: "Pack(s)",
  bg: "Bag(s)",
  pc: "Per Cubic Ft.",
  dz: "Dozen",
  pd: "Pad(s)",
  sf: "Sqft.",
  pnd: "Pound(s)",
  cas: "Case(s)",
  lf: "Linear Ft.",
  wk: "Week(s)",
  mnth: "Month(s)",
  ft: "Per ft Per Day",
  mrkt: "Market Price",
};

export const getTotal = (data) => {
  let total = 0;
  data.resources.forEach((resource) => {
    resource.children.forEach((child) => {
      if (child.value > 0) {
        const itemTotal = child.value * child.price;

        total += itemTotal;
      }
    });
  });

  if(data.markup_type === 'flat' && Number(data.field_ds_markup) > 0){
    total += Number(data.field_ds_markup);
  }

  if(data.markup_type === 'percent' && Number(data.field_ds_percent_markup) > 0){
    total += total * (Number(data.field_ds_percent_markup) / 100);
  }

  return total;
};

export const formatResourcesForSubmit = (data) => {
  const resources = [];
  let total = 0;
  if (!data?.resources) return resources;
  const workarea = data?.field_ds_work_area_allocation?.nid;
  let markupTotal = 0;
  let markup_percent = 0;
  if(Number(data.field_ds_percent_markup) > 0){
    markup_percent = Number(data.field_ds_percent_markup);
  }
  data.resources.forEach((resource) => {
    resource.children.forEach((child) => {
      let item = {};
      if (child.value > 0) {
        item[`term_${child.name}`] = [];

        let itemTotal = child.value * child.price;

        let markup = 0;
        if(markup_percent){
          markup = itemTotal * (markup_percent / 100);
          markupTotal += markup;
          itemTotal += markup;
        }
        item[`term_${child.name}`].push({
          tid: child.tid,
          qty: 1,
          duration: child.value,
          total: itemTotal,
          damage_area: workarea ? workarea : "",
          increment: child.increment,
          file_id: "",
          file_url: "",
          file_name: "",
          price: child.price,
          markup: markup,
          markup_percent: markup_percent,
        });

        total += itemTotal;
        resources.push(item);
      }
    });
  });

  return { resources, total, markup: markupTotal };
};
