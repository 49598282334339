import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Typography,
  Tooltip,
  Card,
} from "@mui/material";
import { useDebounce } from "hooks";
import { getUtcOffset } from "utility";
import { MuiTable, FilterMenu, SelectColumnFilter } from "common/Table";
import { TimeInfo } from "./components/TimeClockTimer";

import {
  dailysheetsDatesSelectors,
} from "features/DailySheets/dailysheetsSlice";
import {
  fetchTimeCards,
  getTimeCardsLoadingSelector,
  getTimeCardsLoadedSelector,
  getTimeCardsPaginationSelector,
  timeCardsSelectors,
  fetchTimeCardUsers,
  getTimeCardUsersLoadingSelector,
  getTimeCardUsersLoadedSelector,
  getTimeCardUsersPaginationSelector,
  timeCardUsersSelectors,
  clearLoaded,
} from "./timeClockSlice";

import { getDivisionDataSelector, } from "features/Job/jobSlice";

import {
  fetchDailySheetsUsers,
  usersSelectors,
} from "features/Users/usersSlice";
import { formatFilterArray, formatFilterUserArray } from "utility/format";
import { capitalizeWords } from "utility";
import "./TimeClock.scss";
import { UserList, ListChecks } from "@phosphor-icons/react";
import { TimeClockQRCode, TimeClockMenuItem, TimeClockDialog } from "components/TimeClock";
import TimeCardUser from "./components/TimeCardUser";
import { size } from "lodash";
import { DefaultLoader } from "common/Loader";
import { GiArchiveResearch } from "react-icons/gi";
import Empty from "@phx/common/Empty";
import Pagination from "common/Table/components/Pagination";
// import { generateTempUUID } from "utility";

import { GetApp } from "@mui/icons-material";
import { exportBatch } from "actions";
import { generateTempUUID } from "utility";

const offSet = getUtcOffset(); // Remember, do not use this for times, (date only)

const TimeCards = () => {
  const dispatch = useDispatch();
  const timeCards = useSelector(timeCardsSelectors.selectAll);
  const timeCardUsers = useSelector(timeCardUsersSelectors.selectAll);
  const pagination = useSelector(getTimeCardsPaginationSelector);
  const overviewPagination = useSelector(getTimeCardUsersPaginationSelector);
  const loading = useSelector(getTimeCardsLoadingSelector);
  const loaded = useSelector(getTimeCardsLoadedSelector);
  const overviewLoaded = useSelector(getTimeCardUsersLoadedSelector);
  const overviewLoading = useSelector(getTimeCardUsersLoadingSelector);
  const dates = useSelector(dailysheetsDatesSelectors.selectAll);
  const users = useSelector(usersSelectors.selectAll);
  const division = useSelector(getDivisionDataSelector);
  // const [updateCardID, setUpdateCardID] = useState(null);
  // const [updateUserID, setUpdateUserID] = useState(null);
  const [toggle, setToggle] = useState('overview');

  const [serviceStatus, setServiceStatus] = useState([]);
  const [date, setDate] = useState([]);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState(toggle === 'listing' ? "date" : "ds_user_full_name");
  const [sort, setSort] = useState("asc");
  const searchQuery = useDebounce(query, 250);
  const [refresh, setRefresh] = useState("");

  useEffect(() => {
    if(division?.nid){
      const params = {
        keywords: searchQuery,
        page,
        order,
        sort,
        rows_per_page: rowsPerPage,
        ...formatFilterArray(serviceStatus, "service_status"),
        ...formatFilterArray(date, "date"),
        ...formatFilterUserArray(user),
      };

      let promise = null;
      if(toggle === 'listing'){
        promise = dispatch(
            fetchTimeCards({
              id: division?.nid,
              // updateCardID: updateCardID,
              params,
            })
        );
      }
      else{
        promise = dispatch(
          fetchTimeCardUsers({
              id: division?.nid,
              // updateUserID: updateUserID,
              params,
            })
        );
      }

      return () => {
        promise.abort();
      };
    }
  }, [
    dispatch,
    division?.nid,
    // updateCardID,
    // updateUserID,
    searchQuery,
    serviceStatus,
    date,
    page,
    order,
    sort,
    user,
    rowsPerPage,
    toggle,
    overviewLoaded,
    loaded,
    refresh,
  ]);

  useEffect(() => {
    if(division?.nid){
      const promise = dispatch(fetchDailySheetsUsers({ id: division.nid }));

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, division?.nid]);

  // const fetchSingle = (id) => {
  //   if(toggle === 'listing'){
  //     dispatch(
  //         fetchTimeCards({
  //           id: division?.nid,
  //           updateCardID: id,
  //           params: {},
  //         })
  //     );
  //   }
  //   else{
  //     dispatch(
  //       fetchTimeCardUsers({
  //           id: division?.nid,
  //           updateUserID: id,
  //           params: {},
  //         })
  //     );
  //   }
  // }

  const handleExport = () => {
    const params = {
      keywords: searchQuery,
      page,
      order,
      sort,
      rows_per_page: rowsPerPage,
      ...formatFilterArray(serviceStatus, "service_status"),
      ...formatFilterArray(date, "date"),
      ...formatFilterUserArray(user),
    };

    dispatch(exportBatch(`/rest/job/daily_sheets/${division?.nid}/time_card_overview`, {
      _export: "csv",
      ...params,
    }));
  };

  const handleRefresh = () => {
    setRefresh(generateTempUUID());
  };

  const data = useMemo(
    () =>
      timeCards.map((card) => ({
        date: `${moment.unix(card.date).utcOffset(offSet).format("MMM Do, YYYY")}
            ${(card?.service_end && moment(card.service_end).format('MM/DD/YY') !== moment.unix(card.date).utcOffset(offSet).format('MM/DD/YY'))
            ? ` - ${moment(card.service_end).format("MMM Do, YYYY")}`
            : ''}`,
        summary: card.summary,
        user: card.ds_user_full_name,
        status: formatStatus(card.service_status),
        hours:
          card.category === "Expense"
            ? ""
            : card.hours_rate
            ? card.hours_rate
                .split("\\,")
                .map((rate, i) => <Typography key={i}>{rate}</Typography>)
            : "",
        time_info: <TimeInfo timeClock={card} hideIcon />,
        detail: (
          <TimeClockDialog
            divisionHash={division.field_public_hash}
            serviceHash={card.hash}
            fullButton
            onClose={() => dispatch(clearLoaded())}
          />
        ),
      })),
    [timeCards, division,]  // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columns = useMemo(
    () => [
      {
        id: "date",
        label: "Created",
        minWidth: 200,
        width: 200,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "summary",
        label: "Summary",
        minWidth: 400,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "user",
        label: "User",
        minWidth: 150,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "status",
        label: "Status",
        minWidth: 150,
        width: 200,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "time_info",
        label: "Times",
        minWidth: 250,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "hours",
        label: "Hours",
        minWidth: 200,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "detail",
        label: "",
        width: 40,
        notSortable: true,
        align: "right",
      },
    ],
    []
  );

  return (
    <>
      <div className="time-cards">
        <div className="heading">
          <div className="toggles">
            <Tooltip title="Overview of Time Cards by User">
              <div className={`button ${toggle === 'overview' ? 'active' : ''}`}>
                <UserList onClick={() => {
                  setPage(0);
                  setToggle('overview');
                }} />
              </div>
            </Tooltip>
            <Tooltip title="List all Time Cards">
              <div className={`button ${toggle === 'listing' ? 'active' : ''}`}>
                <ListChecks onClick={() => {
                  setPage(0);
                  setToggle('listing');
                }} />
              </div>
            </Tooltip>
          </div>
          <FilterMenu
            className="filters"
            search={{
              query,
              setQuery,
            }}
            filters={[
              {
                component: <SelectColumnFilter />,
                props: {
                  filterValue: serviceStatus,
                  setFilter: setServiceStatus,
                  label: "Status",
                  id: "ds-service-status",
                  filterOptions: [
                    {label: 'In Progress', value: 'in_progress'},
                    {label: 'Awaiting Signature', value: 'awaiting_signature'},
                    {label: 'Completed', value: 'complete'},
                  ],
                  multiple: true,
                },
              },
              {
                component: <SelectColumnFilter />,
                props: {
                  filterValue: date,
                  setFilter: setDate,
                  label: "Date",
                  id: "ds-date",
                  filterOptions: dates.map((option, i) => ({
                    label: moment
                      .unix(option.date)
                      .utcOffset(offSet)
                      .format("MMM Do, YYYY"),
                    value: option.date,
                  })),
                  multiple: true,
                },
              },
              {
                component: <SelectColumnFilter />,
                props: {
                  filterValue: user,
                  setFilter: setUser,
                  label: "User",
                  id: "ds-user",
                  filterOptions: users.map((option, i) => ({
                    label: option.name,
                    value: option.uid,
                    type: option.field_temp_nid ? "temporary_user" : "user",
                  })),
                  multiple: true,
                },
              },
            ]}
          />
          <div className="labor-actions">
            <TimeClockQRCode iconOnly menuProps={{className: "button"}} />
            <TimeClockMenuItem iconOnly menuProps={{className: "button"}} onClose={() => dispatch(clearLoaded())} />
            <Tooltip title="Export Time Cards">
              <div className="button" onClick={handleExport}>
                <GetApp />
              </div>
            </Tooltip>
          </div>
        </div>
        {toggle === 'overview' ? (
          <>
          {!size(timeCardUsers) ? (
            (overviewLoading ? (
              <DefaultLoader />
            ) : (
              <Empty message="No time cards were found">
                <GiArchiveResearch />
              </Empty>
            ))
          ) : (
            overviewPagination && (
              <div className="pagination">
                <Pagination
                  pagination={overviewPagination}
                  handleChangePage={(e, newPage) => setPage(newPage)}
                  handleChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
                />
              </div>
            )
          )}
          <div className="overview">
            {timeCardUsers.map((user, i) =>
              <div key={i} className="user">
                <Card className="mui-card">
                  <TimeCardUser
                    user={user}
                    onTimeCardClose={() => dispatch(clearLoaded())}
                    summary
                    handleRefresh={handleRefresh}
                  />
                </Card>
              </div>
            )}
          </div>
          {/* <TimeCardsOverview
            data={timeCardUsers}
            pagination={overviewPagination}
            loading={overviewLoading}
            onTimeCardClose={setUpdateUserID}
            handleChangePage={(e, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
          /> */}
          </>
        ) : (
          <MuiTable
            rows={data}
            columns={columns}
            loading={loading}
            size="small"
            pagination={pagination}
            sort={sort}
            setSort={setSort}
            order={order}
            setOrder={setOrder}
            stickyHeader
            handleChangePage={(e, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
          />
        )}
      </div>
    </>
  );
};

const formatStatus = (status) => {
  const proper = capitalizeWords(status, '_');
  let className = "text-green";
  if(status === 'in_progress'){
    className = 'text-yellow';
  }
  else if(status === 'awaiting_signature'){
    className = 'text-orange';
  }
  return (
    <div className={className}>{proper}</div>
  );
}

TimeCards.propTypes = {};

export default TimeCards;
